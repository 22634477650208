.btn {
  padding: 6px 16px; }

.has-error .control-label,
.has-error .help-block,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label {
  color: #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .control-label, .dark-mode:not(.light)
    .has-error .help-block, .dark-mode:not(.light)
    .has-error .checkbox, .dark-mode:not(.light)
    .has-error .radio-inline, .dark-mode:not(.light)
    .has-error .checkbox-inline, .dark-mode:not(.light)
    .has-error.radio label, .dark-mode:not(.light)
    .has-error.checkbox label {
      color: #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .control-label, .dark-mode.dark
    .has-error .help-block, .dark-mode.dark
    .has-error .checkbox, .dark-mode.dark
    .has-error .radio-inline, .dark-mode.dark
    .has-error .checkbox-inline, .dark-mode.dark
    .has-error.radio label, .dark-mode.dark
    .has-error.checkbox label {
      color: #febb8f; } }

.has-error .form-control {
  border: 1px solid #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .form-control {
      border: 1px solid #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .form-control {
      border: 1px solid #febb8f; } }

a {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) a {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark a {
      color: #fec830; } }
  a:active, a:link, a:visited, a:hover {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) a:active, .dark-mode:not(.light) a:link, .dark-mode:not(.light) a:visited, .dark-mode:not(.light) a:hover {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark a:active, .dark-mode.dark a:link, .dark-mode.dark a:visited, .dark-mode.dark a:hover {
        color: #fec830; } }

.list-group {
  border: none; }
  .list-group .list-group-item {
    border: none;
    border-bottom: 1px solid #ece5df;
    transition: transform 0.2s ease-in-out;
    margin: 0;
    background: transparent;
    padding: 8px; }
    .list-group .list-group-item:hover {
      background: transparent;
      transform: scale(1.01); }
    .list-group .list-group-item .fa-caret-right.pull-right {
      color: #0466f7;
      transition: color 0.2s ease; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
    .list-group .list-group-item:last-child {
      border-bottom: none; }
  .list-group a.list-group-item,
  .list-group a.list-group-item:active,
  .list-group a.list-group-item:hover,
  .list-group a.list-group-item:visited {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .list-group a.list-group-item, .dark-mode:not(.light)
      .list-group a.list-group-item:active, .dark-mode:not(.light)
      .list-group a.list-group-item:hover, .dark-mode:not(.light)
      .list-group a.list-group-item:visited {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .list-group a.list-group-item, .dark-mode.dark
      .list-group a.list-group-item:active, .dark-mode.dark
      .list-group a.list-group-item:hover, .dark-mode.dark
      .list-group a.list-group-item:visited {
        color: #fec830; } }

@media print {
  a[href]:after {
    content: "" !important; } }

.modal .modal-content {
  box-shadow: 1px 1px 1px 0 #d8d8d8;
  background: #ffffff;
  transition: background 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: #ffffff; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: #ffffff; } }
  .modal .modal-content .modal-header {
    border-bottom: 0; }
  .modal .modal-content .modal-title {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .modal .modal-content .modal-title {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .modal .modal-content .modal-title {
        color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: white; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: white; } }

.btn-link,
.btn-default {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        background: transparent; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        background: transparent; } }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link:focus:active,
  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active,
  .btn-default:focus:active {
    color: #0466f7;
    transition: color 0.2s ease;
    background: transparent;
    border: none; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .btn-link:hover, .dark-mode:not(.light) .btn-link:focus, .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:hover, .dark-mode:not(.light)
      .btn-default:focus, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .btn-link:hover, .dark-mode.dark .btn-link:focus, .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:hover, .dark-mode.dark
      .btn-default:focus, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        color: #fec830; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .text-muted {
    color: #ece5df; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .text-muted {
    color: #ece5df; } }

.panel {
  background: none; }
  .panel .panel-heading {
    background: none;
    border-bottom: none;
    font-weight: 500; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-heading {
        color: white; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-heading {
        color: white; } }
  .panel .panel-body {
    box-shadow: 1px 1px 1px 0 #d8d8d8;
    background: #ffffff;
    transition: background 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #939499; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #939499; } }
  .panel .panel-footer {
    background: #939499; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-footer a:active,
      .dark-mode:not(.light) .panel .panel-footer a:link,
      .dark-mode:not(.light) .panel .panel-footer a:visited,
      .dark-mode:not(.light) .panel .panel-footer a:hover {
        color: #1c191d; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-footer a:active,
      .dark-mode.dark .panel .panel-footer a:link,
      .dark-mode.dark .panel .panel-footer a:visited,
      .dark-mode.dark .panel .panel-footer a:hover {
        color: #1c191d; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .panel {
      border: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .panel {
      border: none; } }

a:focus, a:active,
button:focus,
button:active,
.btn:focus,
.btn:active,
[role="button"]:focus,
[role="button"]:active {
  outline: 1px dotted black; }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) body {
    background: #1c191d; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark body {
    background: #1c191d; } }

@media print {
  body {
    background: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .close {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .close {
    color: white; } }

.show-light {
  display: initial; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-light {
      display: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-light {
      display: none; } }

.show-dark {
  display: none; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-dark {
      display: initial; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-dark {
      display: initial; } }

@keyframes from-bottom-right {
  from {
    transform: translate3d(30px, 50px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes half-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 0.5; } }

.side-nav {
  flex: 0 0 250px;
  overflow: hidden;
  overflow-y: auto;
  background: #f8f8f8;
  transition: background 0.2s ease;
  height: calc(100vh - 53px);
  width: 100%; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .side-nav {
      background: #1c191d; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .side-nav {
      background: #1c191d; } }
  .side-nav.collapsed {
    max-width: 0;
    overflow-y: hidden; }
  @media print {
    .side-nav {
      display: none; } }
  @media screen and (max-width: 1200px) {
    .side-nav:not(.collapsed) {
      max-width: 250px; } }
  .side-nav div.links-list {
    padding: 0;
    margin: 20px 0 37px 0; }
    .side-nav div.links-list.bottom {
      margin: 20px 0 20px; }
    @media screen and (max-width: 1200px) {
      .side-nav div.links-list {
        margin: 16px 0 0; }
        .side-nav div.links-list.bottom {
          margin: 16px 0 0; } }
  .side-nav a.link-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    display: block;
    line-height: 36px;
    font-size: 1em;
    text-align: left;
    color: #1c191d;
    vertical-align: middle;
    background-color: transparent;
    padding-left: 12px;
    font-weight: 500;
    border-left: 6px solid transparent; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .side-nav a.link-item {
        color: white; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .side-nav a.link-item {
        color: white; } }
    .side-nav a.link-item > span {
      display: block;
      border-bottom: 1px solid #ece5df;
      padding: 0; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .side-nav a.link-item > span {
          border-bottom: 1px solid #434655; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .side-nav a.link-item > span {
          border-bottom: 1px solid #434655; } }
    .side-nav a.link-item:focus {
      text-decoration: none; }
    .side-nav a.link-item:hover {
      text-decoration: none;
      color: #a91523;
      position: relative;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .side-nav a.link-item:hover {
          color: #fec830; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .side-nav a.link-item:hover {
          color: #fec830; } }
    .side-nav a.link-item.selected {
      text-decoration: none;
      position: relative;
      color: #a91523;
      border-left: 6px solid #a91523; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .side-nav a.link-item.selected {
          color: #fec830;
          border-left: 6px solid #fec830; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .side-nav a.link-item.selected {
          color: #fec830;
          border-left: 6px solid #fec830; } }
    .side-nav a.link-item i {
      width: 24px;
      font-size: 14px;
      margin: 0 4px 0 4px;
      line-height: 0; }
      .side-nav a.link-item i.fa-mobile-phone {
        font-size: 2.2em;
        line-height: 0.5em;
        vertical-align: -35%; }
    .side-nav a.link-item:last-child > span {
      border-bottom: 0; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .side-nav > div.messages {
      color: white; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .side-nav > div.messages {
      color: white; } }
  .side-nav > div.messages > p {
    margin: 0;
    text-align: center;
    font-size: 0.75em; }
