#checkmark {
  fill: lightgray; }

#circle {
  fill: lightgray; }

#circle-loading {
  animation: loading-circle .85s infinite alternate; }

@keyframes loading-circle {
  to {
    fill: green; }
  from {
    fill: lightgray; } }

#checkmark-loading {
  animation: loading-checkmark .85s ease infinite alternate;
  fill: transparent;
  stroke-width: 1;
  stroke: green;
  stroke-dasharray: 46;
  stroke-dashoffset: 0; }

@keyframes loading-checkmark {
  100% {
    stroke-dashoffset: 0;
    fill: green; }
  0% {
    stroke-dashoffset: 46; } }

#circle-done {
  fill-opacity: 0;
  fill: green;
  animation: done-circle 1s forwards; }

@keyframes done-circle {
  to {
    fill-opacity: 1; } }

#checkmark-done {
  fill-opacity: 0;
  fill: green;
  animation: done-checkmark 1s forwards; }

@keyframes done-checkmark {
  to {
    fill-opacity: 1; } }
