.btn {
  padding: 6px 16px; }

.has-error .control-label,
.has-error .help-block,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label {
  color: #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .control-label, .dark-mode:not(.light)
    .has-error .help-block, .dark-mode:not(.light)
    .has-error .checkbox, .dark-mode:not(.light)
    .has-error .radio-inline, .dark-mode:not(.light)
    .has-error .checkbox-inline, .dark-mode:not(.light)
    .has-error.radio label, .dark-mode:not(.light)
    .has-error.checkbox label {
      color: #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .control-label, .dark-mode.dark
    .has-error .help-block, .dark-mode.dark
    .has-error .checkbox, .dark-mode.dark
    .has-error .radio-inline, .dark-mode.dark
    .has-error .checkbox-inline, .dark-mode.dark
    .has-error.radio label, .dark-mode.dark
    .has-error.checkbox label {
      color: #febb8f; } }

.has-error .form-control {
  border: 1px solid #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .form-control {
      border: 1px solid #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .form-control {
      border: 1px solid #febb8f; } }

a {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) a {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark a {
      color: #fec830; } }
  a:active, a:link, a:visited, a:hover {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) a:active, .dark-mode:not(.light) a:link, .dark-mode:not(.light) a:visited, .dark-mode:not(.light) a:hover {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark a:active, .dark-mode.dark a:link, .dark-mode.dark a:visited, .dark-mode.dark a:hover {
        color: #fec830; } }

.list-group {
  border: none; }
  .list-group .list-group-item {
    border: none;
    border-bottom: 1px solid #ece5df;
    transition: transform 0.2s ease-in-out;
    margin: 0;
    background: transparent;
    padding: 8px; }
    .list-group .list-group-item:hover {
      background: transparent;
      transform: scale(1.01); }
    .list-group .list-group-item .fa-caret-right.pull-right {
      color: #0466f7;
      transition: color 0.2s ease; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
    .list-group .list-group-item:last-child {
      border-bottom: none; }
  .list-group a.list-group-item,
  .list-group a.list-group-item:active,
  .list-group a.list-group-item:hover,
  .list-group a.list-group-item:visited {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .list-group a.list-group-item, .dark-mode:not(.light)
      .list-group a.list-group-item:active, .dark-mode:not(.light)
      .list-group a.list-group-item:hover, .dark-mode:not(.light)
      .list-group a.list-group-item:visited {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .list-group a.list-group-item, .dark-mode.dark
      .list-group a.list-group-item:active, .dark-mode.dark
      .list-group a.list-group-item:hover, .dark-mode.dark
      .list-group a.list-group-item:visited {
        color: #fec830; } }

@media print {
  a[href]:after {
    content: "" !important; } }

.modal .modal-content {
  box-shadow: 1px 1px 1px 0 #d8d8d8;
  background: #ffffff;
  transition: background 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: #ffffff; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: #ffffff; } }
  .modal .modal-content .modal-header {
    border-bottom: 0; }
  .modal .modal-content .modal-title {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .modal .modal-content .modal-title {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .modal .modal-content .modal-title {
        color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: white; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: white; } }

.btn-link,
.btn-default {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        background: transparent; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        background: transparent; } }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link:focus:active,
  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active,
  .btn-default:focus:active {
    color: #0466f7;
    transition: color 0.2s ease;
    background: transparent;
    border: none; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .btn-link:hover, .dark-mode:not(.light) .btn-link:focus, .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:hover, .dark-mode:not(.light)
      .btn-default:focus, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .btn-link:hover, .dark-mode.dark .btn-link:focus, .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:hover, .dark-mode.dark
      .btn-default:focus, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        color: #fec830; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .text-muted {
    color: #ece5df; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .text-muted {
    color: #ece5df; } }

.panel {
  background: none; }
  .panel .panel-heading {
    background: none;
    border-bottom: none;
    font-weight: 500; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-heading {
        color: white; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-heading {
        color: white; } }
  .panel .panel-body {
    box-shadow: 1px 1px 1px 0 #d8d8d8;
    background: #ffffff;
    transition: background 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #939499; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #939499; } }
  .panel .panel-footer {
    background: #939499; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-footer a:active,
      .dark-mode:not(.light) .panel .panel-footer a:link,
      .dark-mode:not(.light) .panel .panel-footer a:visited,
      .dark-mode:not(.light) .panel .panel-footer a:hover {
        color: #1c191d; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-footer a:active,
      .dark-mode.dark .panel .panel-footer a:link,
      .dark-mode.dark .panel .panel-footer a:visited,
      .dark-mode.dark .panel .panel-footer a:hover {
        color: #1c191d; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .panel {
      border: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .panel {
      border: none; } }

a:focus, a:active,
button:focus,
button:active,
.btn:focus,
.btn:active,
[role="button"]:focus,
[role="button"]:active {
  outline: 1px dotted black; }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) body {
    background: #1c191d; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark body {
    background: #1c191d; } }

@media print {
  body {
    background: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .close {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .close {
    color: white; } }

.show-light {
  display: initial; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-light {
      display: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-light {
      display: none; } }

.show-dark {
  display: none; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-dark {
      display: initial; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-dark {
      display: initial; } }

@keyframes from-bottom-right {
  from {
    transform: translate3d(30px, 50px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes half-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 0.5; } }

.form-control {
  height: 36px;
  width: 100%; }

.MfaGrid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.MfaGridButton {
  flex: 1 0 100%;
  min-height: 90px;
  position: relative;
  margin-bottom: 16px; }
  .has-error .MfaGridButton button, .MfaGridButton.has-error button {
    border-color: #e00202;
    color: #e00202; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .has-error .MfaGridButton button, .dark-mode:not(.light) .MfaGridButton.has-error button {
        border-color: #febb8f;
        color: #febb8f; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .has-error .MfaGridButton button, .dark-mode.dark .MfaGridButton.has-error button {
        border-color: #febb8f;
        color: #febb8f; } }
  .MfaGridButton button {
    background: #ffffff;
    transition: background 0.2s ease;
    box-shadow: none;
    border: 1px solid #cacaca;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .MfaGridButton button {
        background: #434655; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .MfaGridButton button {
        background: #434655; } }
  .MfaGridButton .select-container {
    position: absolute;
    bottom: 8px;
    width: 100%;
    z-index: 1;
    text-align-last: center; }
    .MfaGridButton .select-container > div {
      padding: 1px 6px; }
    .MfaGridButton .select-container ~ button > div {
      margin-top: 20px; }
  .MfaGridButton .select-container ~ button > div {
    margin-top: -30px; }
    .MfaGridButton .select-container ~ button > div.with-lockout {
      margin-top: -34px; }
  .MfaGridButton.active button {
    background-color: #cacaca; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .MfaGridButton.active button {
        background-color: #1c191d; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .MfaGridButton.active button {
        background-color: #1c191d; } }
