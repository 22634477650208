.btn {
  padding: 6px 16px; }

.has-error .control-label,
.has-error .help-block,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label {
  color: #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .control-label, .dark-mode:not(.light)
    .has-error .help-block, .dark-mode:not(.light)
    .has-error .checkbox, .dark-mode:not(.light)
    .has-error .radio-inline, .dark-mode:not(.light)
    .has-error .checkbox-inline, .dark-mode:not(.light)
    .has-error.radio label, .dark-mode:not(.light)
    .has-error.checkbox label {
      color: #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .control-label, .dark-mode.dark
    .has-error .help-block, .dark-mode.dark
    .has-error .checkbox, .dark-mode.dark
    .has-error .radio-inline, .dark-mode.dark
    .has-error .checkbox-inline, .dark-mode.dark
    .has-error.radio label, .dark-mode.dark
    .has-error.checkbox label {
      color: #febb8f; } }

.has-error .form-control {
  border: 1px solid #e00202; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .has-error .form-control {
      border: 1px solid #febb8f; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .has-error .form-control {
      border: 1px solid #febb8f; } }

a {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) a {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark a {
      color: #fec830; } }
  a:active, a:link, a:visited, a:hover {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) a:active, .dark-mode:not(.light) a:link, .dark-mode:not(.light) a:visited, .dark-mode:not(.light) a:hover {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark a:active, .dark-mode.dark a:link, .dark-mode.dark a:visited, .dark-mode.dark a:hover {
        color: #fec830; } }

.list-group {
  border: none; }
  .list-group .list-group-item {
    border: none;
    border-bottom: 1px solid #ece5df;
    transition: transform 0.2s ease-in-out;
    margin: 0;
    background: transparent;
    padding: 8px; }
    .list-group .list-group-item:hover {
      background: transparent;
      transform: scale(1.01); }
    .list-group .list-group-item .fa-caret-right.pull-right {
      color: #0466f7;
      transition: color 0.2s ease; }
      @media (prefers-color-scheme: dark) {
        .dark-mode:not(.light) .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
      @media (prefers-color-scheme: light) {
        .dark-mode.dark .list-group .list-group-item .fa-caret-right.pull-right {
          color: #fec830; } }
    .list-group .list-group-item:last-child {
      border-bottom: none; }
  .list-group a.list-group-item,
  .list-group a.list-group-item:active,
  .list-group a.list-group-item:hover,
  .list-group a.list-group-item:visited {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .list-group a.list-group-item, .dark-mode:not(.light)
      .list-group a.list-group-item:active, .dark-mode:not(.light)
      .list-group a.list-group-item:hover, .dark-mode:not(.light)
      .list-group a.list-group-item:visited {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .list-group a.list-group-item, .dark-mode.dark
      .list-group a.list-group-item:active, .dark-mode.dark
      .list-group a.list-group-item:hover, .dark-mode.dark
      .list-group a.list-group-item:visited {
        color: #fec830; } }

@media print {
  a[href]:after {
    content: "" !important; } }

.modal .modal-content {
  box-shadow: 1px 1px 1px 0 #d8d8d8;
  background: #ffffff;
  transition: background 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      box-shadow: none; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      background: #434655; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: #ffffff; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: #ffffff; } }
  .modal .modal-content .modal-header {
    border-bottom: 0; }
  .modal .modal-content .modal-title {
    color: #0466f7;
    transition: color 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .modal .modal-content .modal-title {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .modal .modal-content .modal-title {
        color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .modal .modal-content {
      color: white; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .modal .modal-content {
      color: white; } }

.btn-link,
.btn-default {
  color: #0466f7;
  transition: color 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      color: #fec830; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .btn-link, .dark-mode:not(.light)
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        background: transparent; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .btn-link, .dark-mode.dark
    .btn-default {
      background: transparent;
      border: none; }
      .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        background: transparent; } }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link:focus:active,
  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active,
  .btn-default:focus:active {
    color: #0466f7;
    transition: color 0.2s ease;
    background: transparent;
    border: none; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .btn-link:hover, .dark-mode:not(.light) .btn-link:focus, .dark-mode:not(.light) .btn-link:active, .dark-mode:not(.light) .btn-link:focus:active, .dark-mode:not(.light)
      .btn-default:hover, .dark-mode:not(.light)
      .btn-default:focus, .dark-mode:not(.light)
      .btn-default:active, .dark-mode:not(.light)
      .btn-default:focus:active {
        color: #fec830; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .btn-link:hover, .dark-mode.dark .btn-link:focus, .dark-mode.dark .btn-link:active, .dark-mode.dark .btn-link:focus:active, .dark-mode.dark
      .btn-default:hover, .dark-mode.dark
      .btn-default:focus, .dark-mode.dark
      .btn-default:active, .dark-mode.dark
      .btn-default:focus:active {
        color: #fec830; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-default {
    background: none;
    border: none; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark a.btn-primary {
    color: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .text-muted {
    color: #ece5df; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .text-muted {
    color: #ece5df; } }

.panel {
  background: none; }
  .panel .panel-heading {
    background: none;
    border-bottom: none;
    font-weight: 500; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-heading {
        color: white; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-heading {
        color: white; } }
  .panel .panel-body {
    box-shadow: 1px 1px 1px 0 #d8d8d8;
    background: #ffffff;
    transition: background 0.2s ease; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        box-shadow: none; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #434655; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        color: #ffffff; } }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-body {
        background: #939499; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-body {
        background: #939499; } }
  .panel .panel-footer {
    background: #939499; }
    @media (prefers-color-scheme: dark) {
      .dark-mode:not(.light) .panel .panel-footer a:active,
      .dark-mode:not(.light) .panel .panel-footer a:link,
      .dark-mode:not(.light) .panel .panel-footer a:visited,
      .dark-mode:not(.light) .panel .panel-footer a:hover {
        color: #1c191d; } }
    @media (prefers-color-scheme: light) {
      .dark-mode.dark .panel .panel-footer a:active,
      .dark-mode.dark .panel .panel-footer a:link,
      .dark-mode.dark .panel .panel-footer a:visited,
      .dark-mode.dark .panel .panel-footer a:hover {
        color: #1c191d; } }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .panel {
      border: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .panel {
      border: none; } }

a:focus, a:active,
button:focus,
button:active,
.btn:focus,
.btn:active,
[role="button"]:focus,
[role="button"]:active {
  outline: 1px dotted black; }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) body {
    background: #1c191d; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark body {
    background: #1c191d; } }

@media print {
  body {
    background: white; } }

@media (prefers-color-scheme: dark) {
  .dark-mode:not(.light) .close {
    color: white; } }

@media (prefers-color-scheme: light) {
  .dark-mode.dark .close {
    color: white; } }

.show-light {
  display: initial; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-light {
      display: none; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-light {
      display: none; } }

.show-dark {
  display: none; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .show-dark {
      display: initial; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .show-dark {
      display: initial; } }

@keyframes from-bottom-right {
  from {
    transform: translate3d(30px, 50px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes half-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 0.5; } }

html,
body,
#root,
div.main {
  margin: 0;
  height: 100%;
  min-height: 100%; }

html,
body,
.main {
  font-family: "hero-new", sans-serif; }

body {
  text-size-adjust: none; }

.main {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  transition: background 0.2s ease; }
  .main.background {
    background-image: url("./background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .main {
      background: #1c191d;
      color: white; }
      .dark-mode:not(.light) .main.background {
        background-image: url("./background-dark.svg"); } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .main {
      background: #1c191d;
      color: white; }
      .dark-mode.dark .main.background {
        background-image: url("./background-dark.svg"); } }
  .main.xs .dock-top {
    font-size: 1em; }
    .main.xs .dock-top .top-nav > span {
      display: none; }
  .main.ios .wrapper-content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }

.wrapper-page {
  flex: 1 1;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; }
  @media (max-width: 541px) {
    .wrapper-page {
      min-width: 315px; } }

.wrapper-container {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden; }
  .wrapper-container .wrapper-container-vertical {
    flex: 1 1;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-flow: column nowrap; }

.main.unauthenticated .wrapper-container {
  height: calc(100vh - 53px); }

.main:not(.background) .wrapper-page {
  background: #f8f8f8;
  transition: background 0.2s ease; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .main:not(.background) .wrapper-page {
      background: #1c191d; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .main:not(.background) .wrapper-page {
      background: #1c191d; } }

@media (max-width: 768px) {
  .main:not(.background) .content-area {
    padding: 0; } }

.dock-top {
  flex: 0 0 63px;
  font-size: 0.8em;
  border-bottom: 5px solid #a91523;
  background: white;
  display: flex;
  transition: background 0.2s ease;
  position: relative; }
  .main.www .dock-top {
    background: white;
    flex-basis: 58px; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .dock-top {
      background: #434655; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .dock-top {
      background: #434655; } }
  .dock-top > div {
    vertical-align: middle; }
  .dock-top button {
    background-color: transparent;
    border-color: transparent; }
    .dock-top button:focus, .dock-top button:active {
      outline: 1px dotted black; }
  .dock-top > a {
    color: inherit;
    text-align: center; }
    .dock-top > a i {
      font-size: 25px; }
  .dock-top .right {
    justify-self: flex-end;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .dock-top .top-nav {
    min-width: 52.5px; }
    .dock-top .top-nav.left > .fa-fw.fa-lg {
      width: 1em; }
    .dock-top .top-nav > span {
      white-space: nowrap; }
  .dock-top > .header-text,
  .dock-top > .header-text-desktop {
    display: flex;
    align-items: center;
    -ms-flex-align: center; }
    .dock-top > .header-text > .ent-logo,
    .dock-top > .header-text-desktop > .ent-logo {
      margin: auto;
      padding-left: 0; }
      .dock-top > .header-text > .ent-logo:focus, .dock-top > .header-text > .ent-logo:active,
      .dock-top > .header-text-desktop > .ent-logo:focus,
      .dock-top > .header-text-desktop > .ent-logo:active {
        outline: 1px dotted black; }
      .main.www .dock-top > .header-text > .ent-logo .icon.logo, .main.www
      .dock-top > .header-text-desktop > .ent-logo .icon.logo {
        background: url("./entLogo.svg");
        width: 121px;
        height: 41px; }
      .dock-top > .header-text > .ent-logo > img,
      .dock-top > .header-text-desktop > .ent-logo > img {
        height: 28px; }
  .dock-top > .header-text {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .dock-top > .header-text-desktop {
    margin: 10px 0;
    flex: auto;
    flex: 0 0 250px;
    justify-content: flex-start;
    align-items: center; }
    .dock-top > .header-text-desktop > .ent-logo {
      margin: 0 16px; }
  .dock-top > .header-text-desktop + .top-nav.left {
    margin-left: 22px; }

.wrapper-content {
  flex: auto;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto; }
  @media (max-width: 541px) {
    .wrapper-content {
      overflow-x: hidden;
      min-width: 500px;
      width: 100%; } }
  @media screen and (max-width: 780px) {
    .wrapper-content {
      min-width: 540px; } }
  @media screen and (max-width: 760px) {
    .wrapper-content {
      min-width: 530px; } }
  @media screen and (max-width: 740px) {
    .wrapper-content {
      min-width: 520px; } }
  @media screen and (max-width: 720px) {
    .wrapper-content {
      min-width: 510px; } }
  @media screen and (max-width: 700px) {
    .wrapper-content {
      min-width: 500px; } }
  @media screen and (max-width: 680px) {
    .wrapper-content {
      min-width: 490px; } }
  @media screen and (max-width: 660px) {
    .wrapper-content {
      min-width: 480px; } }
  @media screen and (max-width: 640px) {
    .wrapper-content {
      min-width: 470px; } }
  @media screen and (max-width: 620px) {
    .wrapper-content {
      min-width: 460px; } }
  @media screen and (max-width: 600px) {
    .wrapper-content {
      min-width: 450px; } }
  @media screen and (max-width: 580px) {
    .wrapper-content {
      min-width: 440px; } }
  @media screen and (max-width: 560px) {
    .wrapper-content {
      min-width: 430px; } }
  @media screen and (max-width: 540px) {
    .wrapper-content {
      min-width: 420px; } }
  @media screen and (max-width: 520px) {
    .wrapper-content {
      min-width: 410px; } }
  @media screen and (max-width: 500px) {
    .wrapper-content {
      min-width: 400px; } }
  @media screen and (max-width: 480px) {
    .wrapper-content {
      min-width: 390px; } }
  @media screen and (max-width: 460px) {
    .wrapper-content {
      min-width: 380px; } }
  @media screen and (max-width: 440px) {
    .wrapper-content {
      min-width: 370px; } }
  @media screen and (max-width: 420px) {
    .wrapper-content {
      min-width: 360px; } }
  @media screen and (max-width: 400px) {
    .wrapper-content {
      min-width: 350px; } }
  @media screen and (max-width: 380px) {
    .wrapper-content {
      min-width: 340px; } }
  @media screen and (max-width: 360px) {
    .wrapper-content {
      min-width: 330px; } }
  @media screen and (max-width: 340px) {
    .wrapper-content {
      min-width: 320px; } }
  @media screen and (max-width: 320px) {
    .wrapper-content {
      min-width: 310px; } }

.content-area {
  max-width: 1900px;
  padding: 5px 16px 0;
  min-height: 100%; }
  .content-area > * {
    min-height: 100%; }
  @media (max-width: 600px) {
    .content-area {
      padding: 0; } }
  .main.unauthenticated .content-area {
    max-width: none;
    min-height: auto; }
    @media all and (-ms-high-contrast: none) {
      .main.unauthenticated .content-area {
        margin-bottom: 150px; }
        .main.unauthenticated .content-area *::-ms-backdrop {
          margin-bottom: 150px; } }
    .main.unauthenticated .content-area > div[class*="col-"] {
      margin: 0 auto;
      float: none; }

.col-narrow {
  max-width: 450px; }

.col-wide {
  max-width: 1150px; }

.footer {
  flex: 0 0 62px;
  margin: auto 0 10px;
  color: black;
  font-weight: bold;
  line-height: 1.4em;
  background: rgba(255, 255, 255, 0.85);
  padding: 2px 5px 0px 5px;
  border: 2px solid white;
  border-width: 1px 0 1px 0;
  font-size: 0.7em; }
  @media (prefers-color-scheme: dark) {
    .dark-mode:not(.light) .footer a:active,
    .dark-mode:not(.light) .footer a:link,
    .dark-mode:not(.light) .footer a:visited,
    .dark-mode:not(.light) .footer a:hover {
      color: #1125a9; } }
  @media (prefers-color-scheme: light) {
    .dark-mode.dark .footer a:active,
    .dark-mode.dark .footer a:link,
    .dark-mode.dark .footer a:visited,
    .dark-mode.dark .footer a:hover {
      color: #1125a9; } }
  .footer a,
  .footer .d-inline-block {
    display: inline-block; }
  .footer > div:last-child {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center; }

.footnote small,
.footnote .small {
  font-size: 75%;
  line-height: 1.2; }

.buttons {
  margin: 10px 0; }

.main.www button.btn.btn-primary {
  background-color: #31160c;
  border-color: #31160c; }

/* NOTE: EntMobile iOS webview handles clicking incorrectly */
button .fa,
a .fa {
  pointer-events: none; }
  button .fa::before,
  a .fa::before {
    pointer-events: none; }

button img,
a img {
  pointer-events: none; }

/* NOTE: This is an element created by inBrowser */
#restest {
  position: absolute;
  bottom: 0;
  left: 0; }

.skipToContent {
  position: absolute;
  overflow: hidden;
  height: 0; }
  .skipToContent:active, .skipToContent:focus {
    height: 30px;
    background: #f8f8f8;
    padding: 5px;
    margin: 5px;
    z-index: 1; }
