.div-scrollable {
  border: 1px solid #ccc;
  padding: 6px 12px;
  max-height: 340px;
  overflow-y: scroll;
  margin-bottom: 10px; }

.main.xs .div-scrollable {
  max-height: 250px; }

.account-agreement h4 {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em; }

.account-agreement h5 {
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: bold; }
