.form-control.rich-select .Select-menu-outer {
  z-index: 4;
  box-shadow: 0 7px 15px 2px rgba(0, 0, 0, 0.25); }

.form-control.rich-select div[class$='-control'] {
  width: 100%;
  border: none; }

.form-control.rich-select div[class$='-singleValue'] {
  position: relative;
  top: inherit;
  transform: inherit; }

.Select div[class$='-menu'] {
  z-index: 100;
  margin-top: 0; }
